import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import { setCookie, getCookie, deleteCookie } from "../modals/Cookie";
import {
  API_ACCESS_URL,
  LOGIN_REDIRECT_URL,
  WEBSITE_URL,
  redirectTo,
  copyText,
} from "../modals/Constants";
import BottomNavbar from "../other-components/BottomNavbar";
import "../screens/section4.css";
import logo from "../icons/card.jpg";
function Section2Offer() {
  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie("uid")) {
    } else {
      redirectTo(LOGIN_REDIRECT_URL);
    }
  }, []);

  return (
    <div>
      <div className="sticky-containers res-wth bg-cus-color hide-sb rectangle">
        <p
          className="col-view mt-5"
          style={{ color: "white", fontSize: "12px", marginLeft: "1rem" }}
        >
          Payments mode rewarding
        </p>
        <p
          className="text-left"
          style={{
            color: "white",
            fontSize: "15px",
            fontWeight: "bold",
            marginLeft: "1rem",
          }}
        >
          Bill pay & recharge offer
        </p>
        <img src={logo} alt="Your Image" className=" h-w-300 styled-images" />
      </div>

      <BottomNavbar activeBar="offers" />
    </div>
  );
}

export default Section2Offer;
