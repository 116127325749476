import React from 'react'
import { Link } from 'react-router-dom';

function RewardOptions() {
  return (
    <div className="g-v-5 w-100 mg-t-10">
      <Link className="txt-deco-n col-view a-center" to={"/recharge"}>
        <div className='mui_box'>
          <img className='h-w-32' src={require('../icons/neu_deposit.png')} />
        </div>
        <span className='ft-sz-12 mg-t-5 cl-yellow'>Deposit</span>
      </Link>

      <Link className="txt-deco-n col-view a-center" to={"/withdraw?M=B"}>
        <div className='mui_box'>
          <img className='h-w-32' src={require('../icons/neu_withdraw.png')} />
        </div>
        <span className='ft-sz-12 mg-t-5 cl-yellow'>Withdraw</span>
      </Link>

      <Link className="txt-deco-n col-view a-center" to={"/help-support"}>
        <div className='mui_box'>
          <img className='h-w-32' src={require('../icons/neu_help.png')} />
        </div>
        <span className='ft-sz-12 mg-t-5 cl-yellow'>Help</span>
      </Link>

      <Link className="txt-deco-n col-view a-center" to={"/transactions"}>
        <div className='mui_box'>
          <img className='h-w-32' src={require('../icons/neu_transactions.png')} />
        </div>
        <span className='ft-sz-12 mg-t-5 cl-yellow'>Transactions</span>
      </Link>

      <Link className="txt-deco-n col-view a-center" to={"/share"}>
        <div className='mui_box'>
          <img className='h-w-32' src={require('../icons/neu_share.png')} />
        </div>
        <span className='ft-sz-12 mg-t-5 cl-yellow'>Share</span>
      </Link>
    </div>
  );
}

export default RewardOptions;