import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import { setCookie, getCookie, deleteCookie } from "../modals/Cookie";
import {
  API_ACCESS_URL,
  LOGIN_REDIRECT_URL,
  WEBSITE_URL,
  redirectTo,
  copyText,
} from "../modals/Constants";
import BottomNavbar from "../other-components/BottomNavbar";
import image1 from "../icons/letter.jpg";
import image2 from "../icons/lapi.jpg";
import image4 from "../icons/click.jpg";

import image3 from "../icons/search.jpg";
import "../screens/Slides.css";
function Section3Offer() {
  const navigate = useNavigate();
  const imageUrls = [image1, image2, image3, image4];
  useEffect(() => {
    if (getCookie("uid")) {
    } else {
      redirectTo(LOGIN_REDIRECT_URL);
    }
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div>
          <div className=" d-flex flex-col horizontal-scroll-container">
            {imageUrls.map((imageUrl, index) => (
              <div key={index} className="image-item">
                <img src={imageUrl} alt={`${index}`} />
                {/* <div>{text}</div> */}
              </div>
            ))}
          </div>
        </div>
      </div>

      <BottomNavbar activeBar="offers" />
    </div>
  );
}

export default Section3Offer;
