import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import { setCookie, getCookie, deleteCookie } from "../modals/Cookie";
import {
  API_ACCESS_URL,
  LOGIN_REDIRECT_URL,
  WEBSITE_URL,
  redirectTo,
  copyText,
} from "../modals/Constants";
import BottomNavbar from "../other-components/BottomNavbar";
import "../screens/image.css";
import logo from "../icons/login_bg.png";
function Section2Offer() {
  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie("uid")) {
    } else {
      redirectTo(LOGIN_REDIRECT_URL);
    }
  }, []);

  return (
    <div>
      <div className="sticky-container res-wth bg-cus-color hide-sb">
        <img src={logo} alt="Your Image" className=" h-w-300 styled-image" />
      </div>

      <BottomNavbar activeBar="offers" />
    </div>
  );
}

export default Section2Offer;
