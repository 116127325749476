import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import { setCookie, getCookie, deleteCookie } from "../modals/Cookie";
import {
  API_ACCESS_URL,
  LOGIN_REDIRECT_URL,
  WEBSITE_URL,
  redirectTo,
  copyText,
} from "../modals/Constants";
import BottomNavbar from "../other-components/BottomNavbar";
import "../screens/offers.css";

function Section1Offer() {
  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie("uid")) {
    } else {
      redirectTo(LOGIN_REDIRECT_URL);
    }
  }, []);

  return (
    <div className="v-center">
      <div className="res-wth ovf-scrl-y bg-cus-color hide-sb">
        <div className="w-100 col-view v-center pd-15 cl-white">
          <div className="w-100 col-view v-center pd-15 cl-white">
            <div className=" d-flex flex-col horizontal-scroll-container g-v-5 w-100 mg-t-10">
              <Link className="txt-deco-n col-view a-center" to={"/recharge"}>
                <div className="mui_box">
                  <img
                    className="h-w-32"
                    src={require("../icons/neu_deposit.png")}
                  />
                </div>
                <span className="ft-sz-12 mg-t-5 cl-yellow">Deposit</span>
              </Link>

              <Link
                className="txt-deco-n col-view a-center"
                to={"/withdraw?M=B"}
              >
                <div className="mui_box">
                  <img
                    className="h-w-32"
                    src={require("../icons/neu_withdraw.png")}
                  />
                </div>
                <span className="ft-sz-12 mg-t-5 cl-yellow">Withdraw</span>
              </Link>

              <Link
                className="txt-deco-n col-view a-center"
                to={"/help-support"}
              >
                <div className="mui_box">
                  <img
                    className="h-w-32"
                    src={require("../icons/neu_help.png")}
                  />
                </div>
                <span className="ft-sz-12 mg-t-5 cl-yellow">Help</span>
              </Link>

              <Link
                className="txt-deco-n col-view a-center"
                to={"/transactions"}
              >
                <div className="mui_box">
                  <img
                    className="h-w-32"
                    src={require("../icons/neu_transactions.png")}
                  />
                </div>
                <span className="ft-sz-12 mg-t-5 cl-yellow">Transactions</span>
              </Link>

              <Link className="txt-deco-n col-view a-center" to={"/share"}>
                <div className="mui_box">
                  <img
                    className="h-w-32"
                    src={require("../icons/neu_share.png")}
                  />
                </div>
                <span className="ft-sz-12 mg-t-5 cl-yellow">Share</span>
              </Link>
              <Link className="txt-deco-n col-view a-center" to={"/recharge"}>
                <div className="mui_box">
                  <img
                    className="h-w-32"
                    src={require("../icons/neu_deposit.png")}
                  />
                </div>
                <span className="ft-sz-12 mg-t-5 cl-yellow">Deposit</span>
              </Link>

              <Link
                className="txt-deco-n col-view a-center"
                to={"/withdraw?M=B"}
              >
                <div className="mui_box">
                  <img
                    className="h-w-32"
                    src={require("../icons/neu_withdraw.png")}
                  />
                </div>
                <span className="ft-sz-12 mg-t-5 cl-yellow">Withdraw</span>
              </Link>

              <Link
                className="txt-deco-n col-view a-center"
                to={"/help-support"}
              >
                <div className="mui_box">
                  <img
                    className="h-w-32"
                    src={require("../icons/neu_help.png")}
                  />
                </div>
                <span className="ft-sz-12 mg-t-5 cl-yellow">Help</span>
              </Link>

              <Link
                className="txt-deco-n col-view a-center"
                to={"/transactions"}
              >
                <div className="mui_box">
                  <img
                    className="h-w-32"
                    src={require("../icons/neu_transactions.png")}
                  />
                </div>
                <span className="ft-sz-12 mg-t-5 cl-yellow">Transactions</span>
              </Link>

              <Link className="txt-deco-n col-view a-center" to={"/share"}>
                <div className="mui_box">
                  <img
                    className="h-w-32"
                    src={require("../icons/neu_share.png")}
                  />
                </div>
                <span className="ft-sz-12 mg-t-5 cl-yellow">Share</span>
              </Link>
            </div>
            <div
              //   className=" p-5"
              style={{
                borderTop: "3px solid rgba(255, 255, 255, 0.12)",
                width: "100%",
                margin: "10px 0",
              }}
            ></div>
          </div>
        </div>

        {/* <BottomNavbar activeBar="offers" /> */}
      </div>
    </div>
  );
}

export default Section1Offer;
