import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import { setCookie, getCookie, deleteCookie } from "../modals/Cookie";
import {
  API_ACCESS_URL,
  LOGIN_REDIRECT_URL,
  WEBSITE_URL,
  redirectTo,
  copyText,
} from "../modals/Constants";
import BottomNavbar from "../other-components/BottomNavbar";
import "../screens/offers.css";
import Section1Offer from "./Section1Offer";
import Section2Offer from "./Section2Offer";
import Header from "./Header";
import Section3Offer from "./Section3Offer";
import Section4Offer from "./Section4Offer";

function Offers() {
  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie("uid")) {
    } else {
      redirectTo(LOGIN_REDIRECT_URL);
    }
  }, []);

  return (
    <div className="v-center">
      <div className="h-100vh res-wth ovf-scrl-y bg-cus-color hide-sb">
        <div className='w-100 col-view v-center pd-15 cl-white'>
          <div className='ft-sz-18'>Offers</div>
        </div>
        <div>
          <Section1Offer />
          <p
            className="col-view"
            style={{ color: "white", fontSize: "12px", marginLeft: "1rem" }}
          >
            #NeuWayToRewards
          </p>
          <p
            className="text-left"
            style={{
              color: "white",
              fontSize: "15px",
              fontWeight: "bold",
              marginLeft: "1rem",
            }}
          >
            Credit Card offers
          </p>
          <Section2Offer />
          <Section3Offer />
          <div
            //   className=" p-5"
            style={{
              borderTop: "3px solid rgba(255, 255, 255, 0.12)",
              width: "100%",
              margin: "10px",
            }}
          ></div>

          <Section4Offer />
          <div
            //   className=" p-5"
            style={{
              borderTop: "3px solid rgba(255, 255, 255, 0.12)",
              width: "100%",
              margin: "10px",
            }}
          ></div>
          <p
            className="col-view"
            style={{ color: "white", fontSize: "12px", marginLeft: "1rem" }}
          >
            #NeuWayToRewards
          </p>
          <p
            className="text-left"
            style={{
              color: "white",
              fontSize: "15px",
              fontWeight: "bold",
              marginLeft: "1rem",
            }}
          >
            Credit Card offers
          </p>
          <Section2Offer />
          <Section3Offer />
        </div>
      </div>
    </div>
  );
}

export default Offers;
